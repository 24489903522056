<template>
  <a-card :bordered="false">
    <!-- <a-tabs default-active-key="2"  class="top-tabs" >
      <a-tab-pane key="1" tab="分类">
        <div class="flex jc-end mb-4">
          <a-button type="danger" @click="delSelectedClass" v-if="selectedClassKeys.length" class="mr-4" icon="delete">删除</a-button>
          <a-button type="primary" @click="showAddClassModal = true" >新建</a-button>
        </div>
        <a-table
          ref="table"
          size="middle"
          rowKey="templateClassId"
          :customRow="clickClassRow"
          :columns="columns"
          :dataSource="tempClassList"
          :row-selection="{ selectedRowKeys: selectedClassKeys, onChange: onSelectClassChange }"
          >
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="术语表模版" force-render>
        <div class="flex jc-end mb-4">
          <a-button type="danger" @click="delSelectedTempl" class="mr-4" v-if="selectedTempKeys.length" icon="delete">删除</a-button>
          <a-button type="primary" @click="showAddTemp" >新建</a-button>
        </div>
        <a-table
          ref="table"
          size="middle"
          rowKey="templateId"
          :customRow="clickTempRow"
          :pagination="ipagination"
          :columns="columnsTemp"
          :dataSource="tempList"
          :row-selection="{ selectedRowKeys: selectedTempKeys, onChange: onSelectTempChange }"
          >
          <template slot="departments" slot-scope="record">
            <div class="flex">
              <a-tag v-for="item in record.departments" :key="item.departId">{{item.departName}}</a-tag>
            </div>
          </template>

        </a-table>
      </a-tab-pane>
    </a-tabs>
    
    <a-modal v-model="showAddClassModal" title="添加分类" @ok="addTempClass">
      <a-form :form="classForm">
        <a-form-item label="分类名称">
          <a-input 
            placeholder="请输入分类名称"
            v-decorator="['templateClassName', { rules: [{ required: true, message: '请输入分类名称!' }] }]"
          />
        </a-form-item>
        <a-form-item label="序号">
          <a-input 
            placeholder="请输入序号"
            v-decorator="['templateClassId']"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    
    <temp-class-detail :show="showClassModal" @closed="classModalClosed" :id="curClassRow.templateClassId" :row="curClassRow"></temp-class-detail>
    <temp-detail :is-new="isNew" :show="showTempModal" :id="curTempId" @closed="tempModalClosed"></temp-detail>
     -->
  </a-card>
</template>

<script>
  // import { getAction, postAction, deleteAction } from '@/api/manage'
  // import AddTemplate from './modules/AddTemplate'
  // import TempClassDetail from './modules/terms/TempClassDetail'
  // import TempDetail from './modules/terms/TempDetail'
  // import {JeecgListMixin} from '@/mixins/JeecgListMixin'
  // const defaultFields = {
  //   fieldName: '',
  //   fieldClassId: '',
  //   required: false
  // }
  // export default {
  //   name: "TermsList",
  //   mixins: [JeecgListMixin],
  //   components: {
  //     AddTemplate,
  //     TempClassDetail,
  //     TempDetail,
  //   },
  //   data() {
  //     return {
  //       tempClassList: [],
  //       tempList: [],
  //       selectedTempKeys: [],
  //       selectedClassKeys: [],
  //       showClassModal: false,
  //       showTempModal: false,
  //       showAddClassModal: false,
  //       isNew: false,
  //       fieldsList: [Object.assign({}, defaultFields)],
  //       curClassRow: {},
  //       curTempId: 0,
  //       classForm:  this.$form.createForm(this),
  //       columns: [
  //         {
  //           title: '序号',
  //           align: "center",
  //           dataIndex: 'sequenceNo',
  //           width: 80,
  //         },
  //         {
  //           title: '分类名称',
  //           dataIndex: 'templateClassName',
  //         },

  //       ],
  //       columnsTemp: [
  //         {
  //           title: '模版名称',
  //           dataIndex: 'templateName',
  //         },
  //         {
  //           title: '分类',
  //           dataIndex: 'templateClassName',
  //         },
  //         {
  //           title: '适用部门',
  //           scopedSlots: {customRender: "departments"}
  //         },
  //       ],
  //       url: {
  //         list: "/terms/list_template_class",
  //         delete: "/terms/delete_template_class",
  //       },
  //       clickClassRow: (record, index) => ({
  //         on: {
  //           click: () => {
  //             this.curClassRow = record;
  //             this.showClassModal = true;
  //           }
  //         }
  //       }),
  //       clickTempRow: (record, index) => ({
  //         on: {
  //           click: () => {
  //             this.showTempModal = true;
  //             this.curTempId = record.templateId;
  //           }
  //         }
  //       }),
  //     }
  //   },
  //   computed: {
      
  //   },
  //   methods: {
      
  //     classModalClosed(val) {
  //       this.showClassModal=val
  //       this.getTempleClass()
  //     },
  //     tempModalClosed(val) {
  //       this.showTempModal = false
  //       this.isNew = false
  //       this.getTemplateList()
  //     },
  //     showAddTemp() {
  //       this.showTempModal = true;
  //       this.isNew = true;
  //     },
      
  //     getTempleClass() {
  //       getAction('/terms/list_template_class').then(({data}) => {
  //         this.tempClassList = result;
  //       })
  //     },
  //     getTemplateList() {
  //       getAction('/terms/get_template_list').then(({data}) => {
  //         this.tempList = data
  //         console.log(result);
  //       })
  //     },
      
  //     addTempClass() {
  //       this.classForm.validateFields((err,values) => {
  //         if (!err) {
  //           postAction('/terms/add_template_class',values).then(res => {
  //             if (res.success) {
  //               this.$message.success('创建成功！');
  //               this.showAddClassModal = false;
  //               this.getTempleClass();
  //             }
  //           })
            
  //         }
  //       })
  //     },
      
  //     deleteTemplate(id) {
  //       deleteAction('/terms/delete_template',{id: id}).then(res => {
  //         if (res.success) {
  //           this.$message.success('已删除');
  //           this.getTemplateList();
  //         }else{
  //           this.$message.error(res.message);
  //         }
  //       })
  //     },
  //     onSelectTempChange(selected) {
  //       console.log(selected);
  //       this.selectedTempKeys = selected
  //     },
  //     delSelectedTempl() {
  //       var param =  this.selectedTempKeys.join(',')
  //       const self = this;
  //       console.log(param);
  //       this.$confirm({
  //         title: '警告',
  //         content: '您确定要删除所选模版吗？',
  //         onOk() {
  //           deleteAction('/terms/delete_template_batch',{ids:param})
  //             .then(res => {
  //               if (res.success) {
  //                 self.$message.success(res.message);
  //                 self.getTemplateList();
  //               }else{
  //                 self.$message.error(res.message);
  //               }
  //             })
            
  //         }
  //       })
  //     },

  //     onSelectClassChange(selected) {
  //       console.log(selected);
  //       this.selectedClassKeys = selected
  //     },

  //     delSelectedClass() {
  //       var param =  this.selectedClassKeys.join(',')
  //       const self = this
  //       console.log(param);
  //       this.$confirm({
  //         title: '警告',
  //         content: '您确定要删除所选分类吗？',
  //         onOk() {
  //           deleteAction('/terms/delete_template_class_batch',{ids:param})
  //             .then(res => {
  //               if (res.success) {
  //                 self.$message.success(res.message);
  //                 self.getTempleClass();
  //               }else{
  //                 self.$message.error(res.message);
  //               }
  //             })
  //         }
  //       })
  //     },
      
  //   },
  //   created() {
  //     this.getTempleClass()
  //     this.getTemplateList()
  //   }

  // }
</script>

<style lang="stylus">
.top-tabs{
  margin-top: -24px;
}
</style>

